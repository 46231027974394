// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__PageSizeSwitcher__pageSizeSwitcher .jut__PageSizeSwitcher__dropdownButton{color:var(--GW-TEXT-COLOR-1);font-weight:var(--GW-FONT-WEIGHT-REGULAR);height:1.5rem;padding:0;padding-left:var(--GW-SPACING-2)}.jut__PageSizeSwitcher__pageSizeSwitcher .jut__PageSizeSwitcher__dropdownButtonIcon{font-size:var(--GW-DATA-VIEWS-PAGINATION-ICON-SIZE)}.jut__PageSizeSwitcher__pageSizeSwitcher .jut__PageSizeSwitcher__dropdown{min-width:auto}.jut__PageSizeSwitcher__pageSizeSwitcher .jut__PageSizeSwitcher__rowsPerPage{margin-right:var(--GW-SPACING-4)}", "",{"version":3,"sources":["webpack://./../../../../../../src/dataview/features/pagination/Pagination/PageSizeSwitcher/PageSizeSwitcher.module.scss","webpack://./../../platform/@jutro/legacy/dataview/features/pagination/Pagination/PageSizeSwitcher/PageSizeSwitcher.module.css"],"names":[],"mappings":"AACE,gFAIE,4BAAA,CACA,yCAAA,CAFA,aAAA,CAFA,SAAA,CACA,gCCGJ,CDGE,oFACE,mDCDJ,CDIE,0EACE,cCFJ,CDKE,6EACE,gCCHJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageSizeSwitcher": "jut__PageSizeSwitcher__pageSizeSwitcher",
	"dropdownButton": "jut__PageSizeSwitcher__dropdownButton",
	"dropdownButtonIcon": "jut__PageSizeSwitcher__dropdownButtonIcon",
	"dropdown": "jut__PageSizeSwitcher__dropdown",
	"rowsPerPage": "jut__PageSizeSwitcher__rowsPerPage"
};
export default ___CSS_LOADER_EXPORT___;
