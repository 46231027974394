// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__SimpleDateField__container{gap:var(--GW-SIMPLE-DATE-FIELD-GAP)}.jut__SimpleDateField__twoDigits{width:3rem}.jut__SimpleDateField__fourDigits{width:4rem}.jut__SimpleDateField__hidden{border:0;clip:rect(0,0,0,0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}", "",{"version":3,"sources":["webpack://./../../../../../../src/widgets/inputs/date/SimpleDateField/SimpleDateField.module.scss","webpack://./../../platform/@jutro/components/widgets/inputs/date/SimpleDateField/SimpleDateField.module.css","webpack://./../../../../../../node_modules/@jutro/theme/assets/sass/mixins/common-mixins.scss"],"names":[],"mappings":"AAEA,iCACE,mCCEF,CDCA,iCACE,UCEF,CDCA,kCACE,UCEF,CDCA,8BEgBE,QAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CACA,SAAA,CACA,iBAAA,CACA,SDbF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "jut__SimpleDateField__container",
	"twoDigits": "jut__SimpleDateField__twoDigits",
	"fourDigits": "jut__SimpleDateField__fourDigits",
	"hidden": "jut__SimpleDateField__hidden"
};
export default ___CSS_LOADER_EXPORT___;
