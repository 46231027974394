// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "ul.jut__TreeView__treeView{list-style-type:none;margin-bottom:0;padding-inline-start:unset;padding:0 var(--GW-LAYOUT-3) var(--GW-LAYOUT-2);width:100%}", "",{"version":3,"sources":["webpack://./../../../src/TreeView/TreeView.module.scss","webpack://./../../platform/@jutro/components/TreeView/TreeView.module.css"],"names":[],"mappings":"AAAA,2BAGE,oBAAA,CAEA,eAAA,CAHA,0BAAA,CAEA,+CAAA,CAHA,UCKF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"treeView": "jut__TreeView__treeView"
};
export default ___CSS_LOADER_EXPORT___;
