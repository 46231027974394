// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__UnresolvedComponent__unresolvedComponent{background-color:var(--GW-COLOR-WARNING);color:var(--GW-COLOR-WARNING-CONTRAST);height:auto;text-align:center;width:100%}.jut__UnresolvedComponent__unresolvedComponent code{padding:0 var(--GW-SPACING-3)}", "",{"version":3,"sources":["webpack://./../../../../src/render/UnresolvedComponent/UnresolvedComponent.module.scss","webpack://./../../platform/@jutro/uiconfig/render/UnresolvedComponent/UnresolvedComponent.module.css"],"names":[],"mappings":"AAAA,+CAIE,wCAAA,CACA,sCAAA,CAHA,WAAA,CACA,iBAAA,CAFA,UCKF,CDCE,oDACE,6BCCJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unresolvedComponent": "jut__UnresolvedComponent__unresolvedComponent"
};
export default ___CSS_LOADER_EXPORT___;
