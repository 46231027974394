// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__TooltipIcon__tooltipIcon{background-color:transparent;border:none;color:var(--GW-TOOLTIP-ICON-COLOR);display:inline-flex;outline:none;padding:0;position:relative}.jut__TooltipIcon__tooltipIcon>svg{position:relative;width:auto}.jut__TooltipIcon__tooltipIcon:focus>svg{border-radius:var(--GW-BORDER-RADIUS-FULL);box-shadow:0 0 0 2px var(--GW-FOCUS-COLOR-LIGHT);outline:none}.jut__TooltipIcon__left,.jut__TooltipIcon__top{position:absolute}.jut__TooltipIcon__left{align-items:center;height:var(--GW-FIELD-COMPONENT-HEIGHT);right:calc(var(--GW-SPACING-8)*-1)}.jut__TooltipIcon__disabled{cursor:not-allowed;opacity:var(--GW-OPACITY-DISABLED)}", "",{"version":3,"sources":["webpack://./../../../../src/widgets/Tooltip/TooltipIcon.module.scss","webpack://./../../platform/@jutro/components/widgets/Tooltip/TooltipIcon.module.css","webpack://./../../../../../../node_modules/@jutro/theme/assets/sass/mixins/common-mixins.scss"],"names":[],"mappings":"AAEA,+BAIE,4BAAA,CACA,WAAA,CAJA,kCAAA,CAEA,mBAAA,CAGA,YAAA,CAJA,SAAA,CAKA,iBCEF,CDAE,mCACE,iBAAA,CACA,UCEJ,CDEI,yCAGE,0CAAA,CEHF,gDAPU,CAEZ,YDQF,CDSA,+CAHE,iBCKF,CDFA,wBAIE,kBAAA,CADA,uCAAA,CADA,kCCAF,CDKA,4BAEE,kBAAA,CADA,kCCDF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipIcon": "jut__TooltipIcon__tooltipIcon",
	"left": "jut__TooltipIcon__left",
	"top": "jut__TooltipIcon__top",
	"disabled": "jut__TooltipIcon__disabled"
};
export default ___CSS_LOADER_EXPORT___;
