// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__FilterBar__titleBar{align-items:center;display:flex;justify-content:space-between}.jut__FilterBar__filters{background-color:var(--GW-DATA-VIEW-FILTERS-BACKGROUND-COLOR);margin-bottom:var(--GW-LAYOUT-4);padding:var(--GW-LAYOUT-4) var(--GW-LAYOUT-4) 0}.jut__FilterBar__filters .jut__FilterBar__titleDivider{border-top:1px solid rgba(125,145,172,.24);height:1px;margin:var(--GW-SPACING-4) 0}.jut__FilterBar__filterTitle{color:var(--GW-FILTER-BAR-FILTERS-COLOR);font-size:var(--GW-FONT-SIZE-H4);font-weight:var(--GW-FONT-WEIGHT-SEMI-BOLD);line-height:var(--GW-LINE-HEIGHT-H4)}.jut__FilterBar__filterItem{margin-bottom:var(--GW-SPACING-3);min-width:var(--GW-FILTER-BAR-INPUT-MIN-WIDTH)}.jut__FilterBar__filterBarContent{gap:0 var(--GW-LAYOUT-4)}", "",{"version":3,"sources":["webpack://./../../../../../../src/dataview/features/filtering/FilterBar/FilterBar.module.scss","webpack://./../../platform/@jutro/legacy/dataview/features/filtering/FilterBar/FilterBar.module.css","webpack://./../../../../../../node_modules/@jutro/theme/assets/sass/mixins/typography-mixins.scss"],"names":[],"mappings":"AAEA,0BAGE,kBAAA,CAFA,YAAA,CACA,6BCGF,CDCA,yBACE,6DAAA,CAEA,gCAAA,CADA,+CCGF,CDAE,uDAGE,0CAAA,CAFA,UAAA,CACA,4BCGJ,CDEA,6BACE,wCAAA,CELA,gCAAA,CFMA,2CAAA,CELA,oCDQF,CDEA,4BAEE,iCAAA,CADA,8CCEF,CDEA,kCACE,wBCCF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleBar": "jut__FilterBar__titleBar",
	"filters": "jut__FilterBar__filters",
	"titleDivider": "jut__FilterBar__titleDivider",
	"filterTitle": "jut__FilterBar__filterTitle",
	"filterItem": "jut__FilterBar__filterItem",
	"filterBarContent": "jut__FilterBar__filterBarContent"
};
export default ___CSS_LOADER_EXPORT___;
