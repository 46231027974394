// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__Container__container{margin:0 var(--GW-LAYOUT-6)}@media (min-width:1448px){.jut__Container__container{margin:0 auto;max-width:1448px}.jut__Container__container:after,.jut__Container__container:before{content:\"\";margin-left:var(--GW-LAYOUT-10)}}@media (max-width:1024px){.jut__Container__container{margin:0 var(--GW-LAYOUT-8)}}@media (max-width:580px){.jut__Container__container{margin:0 var(--GW-LAYOUT-2)}}.jut__Container__container.jut__Container__fluid{margin:0;max-width:100%}.jut__Container__container.jut__Container__fluid:after,.jut__Container__container.jut__Container__fluid:before{margin-left:0}", "",{"version":3,"sources":["webpack://./../../../../src/components/Container/Container.module.scss","webpack://./../../platform/@jutro/legacy/components/Container/Container.module.css","webpack://./../../../../../../node_modules/@jutro/theme/assets/sass/mixins/breakpoints-mixins.scss"],"names":[],"mappings":"AAEA,2BACE,2BCEF,CDCE,0BAJF,2BAMI,aAAA,CADA,gBCGF,CDAE,mEAEE,UAAA,CACA,+BCCJ,CACF,CC+BI,0BF5CJ,2BAgBI,2BCCF,CACF,CC0BI,yBF5CJ,2BAoBI,2BCEF,CACF,CDAE,iDAEE,QAAA,CADA,cCGJ,CDAI,+GAEE,aCCN","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "jut__Container__container",
	"fluid": "jut__Container__fluid"
};
export default ___CSS_LOADER_EXPORT___;
