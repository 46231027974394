// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__Badge__badgeContainer{display:inline-flex}.jut__Badge__badge{font-weight:var(--GW-FONT-WEIGHT-SEMI-BOLD)}.jut__Badge__badge.jut__Badge__notification{background-color:var(--GW-BADGE-NOTIFICATION-BACKGROUND-COLOR);border:var(--GW-BADGE-NOTIFICATION-BORDER);box-shadow:var(--GW-SHADOW-1);color:var(--GW-BADGE-NOTIFICATION-COLOR)}.jut__Badge__badge.jut__Badge__primary{background-color:var(--GW-BADGE-PRIMARY-BACKGROUND-COLOR);color:var(--GW-BADGE-PRIMARY-COLOR)}.jut__Badge__badge.jut__Badge__inverse{background-color:var(--GW-BADGE-INVERSE-BACKGROUND-COLOR);color:var(--GW-BADGE-INVERSE-COLOR)}.jut__Badge__liveRegionContext{display:block;height:1px;overflow:hidden;position:absolute;width:1px}", "",{"version":3,"sources":["webpack://./../../../../src/widgets/Badge/Badge.module.scss","webpack://./../../platform/@jutro/components/widgets/Badge/Badge.module.css"],"names":[],"mappings":"AAAA,4BACE,mBCCF,CDEA,mBACE,2CCCF,CDCE,4CAEE,8DAAA,CACA,0CAAA,CACA,6BAAA,CAHA,wCCIJ,CDEE,uCAEE,yDAAA,CADA,mCCCJ,CDGE,uCAEE,yDAAA,CADA,mCCAJ,CDKA,+BACE,aAAA,CACA,UAAA,CACA,eAAA,CACA,iBAAA,CACA,SCFF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badgeContainer": "jut__Badge__badgeContainer",
	"badge": "jut__Badge__badge",
	"notification": "jut__Badge__notification",
	"primary": "jut__Badge__primary",
	"inverse": "jut__Badge__inverse",
	"liveRegionContext": "jut__Badge__liveRegionContext"
};
export default ___CSS_LOADER_EXPORT___;
