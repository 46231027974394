// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__CheckboxGroup__wrapper{border:none;margin:0;padding:var(--JDS-CHECKBOX-GROUP-PADDING-INTERNAL)}.jut__CheckboxGroup__control.jut__CheckboxGroup__horizontal{display:flex;flex-wrap:wrap;gap:var(--JDS-CHECKBOX-GROUP-GAP-HORIZONTAL-INTERNAL)}.jut__CheckboxGroup__control.jut__CheckboxGroup__vertical{display:flex;flex-direction:column;gap:var(--JDS-CHECKBOX-GROUP-GAP-VERTICAL-INTERNAL)}.jut__CheckboxGroup__messages{margin-top:var(--JDS-CHECKBOX-GROUP-GAP-VERTICAL-INTERNAL)}.jut__CheckboxGroup__label{align-items:flex-start}", "",{"version":3,"sources":["webpack://./../../../../src/fields/CheckboxGroup/CheckboxGroup.module.scss","webpack://./../../platform/@jutro/components/fields/CheckboxGroup/CheckboxGroup.module.css"],"names":[],"mappings":"AAAA,6BAGE,WAAA,CAFA,QAAA,CACA,kDCEF,CDIE,4DACE,YAAA,CACA,cAAA,CACA,qDCDJ,CDIE,0DACE,YAAA,CACA,qBAAA,CACA,mDCFJ,CDMA,8BACE,0DCHF,CDMA,2BACE,sBCHF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "jut__CheckboxGroup__wrapper",
	"control": "jut__CheckboxGroup__control",
	"horizontal": "jut__CheckboxGroup__horizontal",
	"vertical": "jut__CheckboxGroup__vertical",
	"messages": "jut__CheckboxGroup__messages",
	"label": "jut__CheckboxGroup__label"
};
export default ___CSS_LOADER_EXPORT___;
