// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__MapArea_DEPRECATED__mapArea{border:1px solid var(--GW-BORDER-COLOR);border-radius:var(--GW-BORDER-RADIUS);padding-bottom:calc(var(--GW-MAP-AREA-ASPECT-RATIO) - 2px);position:relative;width:100%}", "",{"version":3,"sources":["webpack://./../../../../src/components/MapArea/MapArea_DEPRECATED.module.scss","webpack://./../../platform/@jutro/legacy/components/MapArea/MapArea_DEPRECATED.module.css"],"names":[],"mappings":"AAAA,kCAME,uCAAA,CACA,qCAAA,CAFA,0DAAA,CAJA,iBAAA,CACA,UCIF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mapArea": "jut__MapArea_DEPRECATED__mapArea"
};
export default ___CSS_LOADER_EXPORT___;
