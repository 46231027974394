// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__TableView__wrapper{background-color:var(--GW-BACKGROUND-COLOR);color:var(--GW-TABLE-VIEW-COLOR)}.jut__TableView__tableWrapper{overflow:auto}.jut__TableView__table{border-bottom:var(--GW-TABLE-VIEW-TABLE-SEPARATOR);border-collapse:collapse;width:100%}.jut__TableView__pagination{padding:var(--GW-LAYOUT-4) var(--GW-LAYOUT-1) 0 var(--GW-LAYOUT-1)}.jut__TableView__stripedRow{background-color:var(--GW-TABLE-VIEW-ROW-EVEN-BACKGROUND-COLOR);color:var(--GW-TABLE-VIEW-ROW-EVEN-FONT-COLOR)}.jut__TableView__noCardHeading{height:0}", "",{"version":3,"sources":["webpack://./../../../../src/dataview/TableView/TableView.module.scss","webpack://./../../platform/@jutro/legacy/dataview/TableView/TableView.module.css"],"names":[],"mappings":"AAAA,yBACE,2CAAA,CACA,gCCCF,CDEA,8BACE,aCCF,CDEA,uBAGE,kDAAA,CADA,wBAAA,CADA,UCGF,CDEA,4BACE,kECCF,CDEA,4BAEE,+DAAA,CADA,8CCEF,CDEA,+BACE,QCCF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "jut__TableView__wrapper",
	"tableWrapper": "jut__TableView__tableWrapper",
	"table": "jut__TableView__table",
	"pagination": "jut__TableView__pagination",
	"stripedRow": "jut__TableView__stripedRow",
	"noCardHeading": "jut__TableView__noCardHeading"
};
export default ___CSS_LOADER_EXPORT___;
