// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__TextHighlight__highlight{background-color:var(--GW-HIGHLIGHT-TEXT-HIGHLIGHT-BACKGROUND-COLOR)}.jut__TextHighlight__screenReaderLabel{border:0;clip:rect(0,0,0,0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}", "",{"version":3,"sources":["webpack://./../../../../src/internal/TextHighlight/TextHighlight.module.scss","webpack://./../../platform/@jutro/components/internal/TextHighlight/TextHighlight.module.css","webpack://./../../../../../../node_modules/@jutro/theme/assets/sass/mixins/common-mixins.scss"],"names":[],"mappings":"AAEA,+BACE,oECEF,CDCA,uCEwBE,QAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CACA,SAAA,CACA,iBAAA,CACA,SDrBF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highlight": "jut__TextHighlight__highlight",
	"screenReaderLabel": "jut__TextHighlight__screenReaderLabel"
};
export default ___CSS_LOADER_EXPORT___;
