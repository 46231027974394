// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__TimeField__input{border:0;cursor:inherit;outline:0;width:100%}.jut__TimeField__select:focus-within{border-color:var(--GW-FIELD-COMPONENT-BORDER-COLOR-FOCUS);box-shadow:0 0 0 2px var(--GW-FOCUS-COLOR-LIGHT);outline:none}.jut__TimeField__readOnly{font-weight:var(--GW-FONT-WEIGHT-SEMI-BOLD)}", "",{"version":3,"sources":["webpack://./../../../../../../src/widgets/inputs/date/TimeField/TimeField.module.scss","webpack://./../../platform/@jutro/components/widgets/inputs/date/TimeField/TimeField.module.css","webpack://./../../../../../../node_modules/@jutro/theme/assets/sass/mixins/common-mixins.scss"],"names":[],"mappings":"AAEA,uBAEE,QAAA,CAEA,cAAA,CADA,SAAA,CAFA,UCKF,CDEE,qCAGE,yDAAA,CEIA,gDAPU,CAEZ,YDEF,CDGA,0BACE,2CCAF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "jut__TimeField__input",
	"select": "jut__TimeField__select",
	"readOnly": "jut__TimeField__readOnly"
};
export default ___CSS_LOADER_EXPORT___;
