// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "ul.jut__NavigationContent__navigationContent{list-style-type:none;margin:0;outline:none;padding-left:0;padding-right:0;width:100%}.jut__NavigationContent__contextSwitcherContainer{display:flex;justify-content:center}", "",{"version":3,"sources":["webpack://./../../../src/NavBar/NavigationContent.module.scss","webpack://./../../platform/@jutro/router/NavBar/NavigationContent.module.css"],"names":[],"mappings":"AAAA,6CAEE,oBAAA,CACA,QAAA,CAFA,YAAA,CAGA,cAAA,CACA,eAAA,CACA,UCCF,CDEA,kDACE,YAAA,CACA,sBCCF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigationContent": "jut__NavigationContent__navigationContent",
	"contextSwitcherContainer": "jut__NavigationContent__contextSwitcherContainer"
};
export default ___CSS_LOADER_EXPORT___;
