// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__AddressReadonly__addressReadonly{font-weight:var(--GW-FONT-WEIGHT-SEMI-BOLD);white-space:pre-wrap}.jut__AddressReadonly__inlineField{display:inline-block}", "",{"version":3,"sources":["webpack://./../../../../src/address/addressReadOnly/AddressReadonly.module.scss","webpack://./../../platform/@jutro/legacy/address/addressReadOnly/AddressReadonly.module.css"],"names":[],"mappings":"AAAA,uCAEE,2CAAA,CADA,oBCEF,CDEA,mCACE,oBCCF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addressReadonly": "jut__AddressReadonly__addressReadonly",
	"inlineField": "jut__AddressReadonly__inlineField"
};
export default ___CSS_LOADER_EXPORT___;
