// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__UploadingProgress__card.jut__UploadingProgress__card{background-color:var(--GW-BACKGROUND-COLOR);border:none;border-radius:var(--GW-BORDER-RADIUS);box-shadow:var(--GW-SHADOW-2);padding:var(--GW-SPACING-3) var(--GW-SPACING-4);width:100%}.jut__UploadingProgress__cardMultiple.jut__UploadingProgress__cardMultiple{background-color:var(--GW-BACKGROUND-COLOR-BODY);border:none;border-radius:0;padding:var(--GW-SPACING-3);width:100%}.jut__UploadingProgress__cardMultiple.jut__UploadingProgress__cardMultiple+.jut__UploadingProgress__cardMultiple.jut__UploadingProgress__cardMultiple{margin-top:var(--GW-SPACING-1)}.jut__UploadingProgress__label{padding-bottom:var(--GW-SPACING-2)}.jut__UploadingProgress__container{display:flex}.jut__UploadingProgress__progressWrapper{flex:1 1;margin-right:var(--GW-SPACING-3)}.jut__UploadingProgress__actionButton{height:var(--GW-BUTTON-HEIGHT-SMALL);margin-top:var(--GW-SPACING-3);width:var(--GW-BUTTON-HEIGHT-SMALL)}.jut__UploadingProgress__cancelled .jut__SimpleProgressBar__progressBarSteps{background-color:var(--GW-COLOR-WARNING)}.jut__UploadingProgress__failed .jut__SimpleProgressBar__progressBarSteps{background-color:var(--GW-COLOR-ERROR)}.jut__UploadingProgress__done .jut__SimpleProgressBar__progressBarSteps{background-color:var(--GW-COLOR-SUCCESS)}", "",{"version":3,"sources":["webpack://./../../../../../src/internal/FileUploadField/UploadingProgress/UploadingProgress.module.scss","webpack://./../../platform/@jutro/components/internal/FileUploadField/UploadingProgress/UploadingProgress.module.css"],"names":[],"mappings":"AAEA,2DAGE,2CAAA,CADA,WAAA,CAEA,qCAAA,CACA,6BAAA,CAJA,+CAAA,CAKA,UCEF,CDCA,2EAIE,gDAAA,CAFA,WAAA,CACA,eAAA,CAFA,2BAAA,CAIA,UCEF,CDAE,sJACE,8BCEJ,CDEA,+BACE,kCCCF,CDEA,mCACE,YCCF,CDEA,yCACE,QAAA,CACA,gCCCF,CDEA,sCAEE,oCAAA,CACA,8BAAA,CAFA,mCCGF,CDEA,6EACE,wCCCF,CDEA,0EACE,sCCCF,CDEA,wEACE,wCCCF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "jut__UploadingProgress__card",
	"cardMultiple": "jut__UploadingProgress__cardMultiple",
	"label": "jut__UploadingProgress__label",
	"container": "jut__UploadingProgress__container",
	"progressWrapper": "jut__UploadingProgress__progressWrapper",
	"actionButton": "jut__UploadingProgress__actionButton",
	"cancelled": "jut__UploadingProgress__cancelled",
	"failed": "jut__UploadingProgress__failed",
	"done": "jut__UploadingProgress__done"
};
export default ___CSS_LOADER_EXPORT___;
