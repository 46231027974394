// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__MultipleFilesUploadStatusBar__statusBarErrorText{padding-left:var(--GW-SPACING-2)}.jut__MultipleFilesUploadStatusBar__statusBarContainer{display:flex;font-size:var(--GW-FONT-SIZE-LABEL);font-weight:var(--GW-FONT-WEIGHT-REGULAR);justify-content:space-between;padding:var(--GW-SPACING-2) 0;width:100%}.jut__MultipleFilesUploadStatusBar__statusBarTextContainer{align-items:center;display:flex}.jut__MultipleFilesUploadStatusBar__statusBarTextContainer svg{font-size:var(--GW-ICON-FONT-SIZE-3);height:auto}.jut__MultipleFilesUploadStatusBar__errorIcon{color:var(--GW-COLOR-ERROR)}.jut__MultipleFilesUploadStatusBar__successIcon{color:var(--GW-COLOR-SUCCESS)}", "",{"version":3,"sources":["webpack://./../../../../../../src/internal/FileUploadField/MultipleFilesUploadField/components/MultipleFilesUploadStatusBar.module.scss","webpack://./../../platform/@jutro/components/internal/FileUploadField/MultipleFilesUploadField/components/MultipleFilesUploadStatusBar.module.css"],"names":[],"mappings":"AAAA,uDACE,gCCCF,CDEA,uDACE,YAAA,CAIA,mCAAA,CACA,yCAAA,CAJA,6BAAA,CACA,6BAAA,CACA,UCGF,CDEA,2DAEE,kBAAA,CADA,YCEF,CDCE,+DACE,oCAAA,CACA,WCCJ,CDGA,8CACE,2BCAF,CDGA,gDACE,6BCAF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statusBarErrorText": "jut__MultipleFilesUploadStatusBar__statusBarErrorText",
	"statusBarContainer": "jut__MultipleFilesUploadStatusBar__statusBarContainer",
	"statusBarTextContainer": "jut__MultipleFilesUploadStatusBar__statusBarTextContainer",
	"errorIcon": "jut__MultipleFilesUploadStatusBar__errorIcon",
	"successIcon": "jut__MultipleFilesUploadStatusBar__successIcon"
};
export default ___CSS_LOADER_EXPORT___;
