// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__ClickableCard__clickableCard{background-color:var(--GW-CLICKABLE-ELEMENT-BACKGROUND-COLOR);border:none;border-radius:var(--GW-BORDER-RADIUS);box-shadow:var(--GW-SHADOW-2);color:var(--GW-CLICKABLE-ELEMENT-COLOR);display:flex;flex-direction:column;line-height:var(--GW-LINE-HEIGHT-BODY);min-width:14.5rem;padding:var(--GW-CARD-PADDING)}.jut__ClickableCard__clickableCard:hover:not(.jut__ClickableCard__disabled){background-color:var(--GW-CLICKABLE-ELEMENT-BACKGROUND-COLOR-HOVER);cursor:pointer;outline:none}.jut__ClickableCard__clickableCard:focus{box-shadow:0 0 0 2px var(--GW-FOCUS-COLOR-LIGHT);outline:none}.jut__ClickableCard__clickableCard:active:focus,.jut__ClickableCard__clickableCard:active:not(:focus-within,.jut__ClickableCard__disabled){background-color:var(--GW-CLICKABLE-ELEMENT-BACKGROUND-COLOR-ACTIVE);box-shadow:none;outline:none}.jut__ClickableCard__clickableCard .jut__ClickableCard__activeKeypress,.jut__ClickableCard__clickableCard .jut__ClickableCard__activeKeypress:focus{background-color:var(--GW-CLICKABLE-ELEMENT-BACKGROUND-COLOR-ACTIVE);box-shadow:none;outline:none}.jut__ClickableCard__clickableCard.jut__ClickableCard__disabled{cursor:not-allowed;opacity:var(--GW-OPACITY-DISABLED)}", "",{"version":3,"sources":["webpack://./../../../../src/widgets/clickableCard/ClickableCard.module.scss","webpack://./../../platform/@jutro/components/widgets/clickableCard/ClickableCard.module.css","webpack://./../../../../../../node_modules/@jutro/theme/assets/sass/mixins/common-mixins.scss"],"names":[],"mappings":"AAEA,mCAIE,6DAAA,CADA,WAAA,CAEA,qCAAA,CAEA,6BAAA,CAEA,uCAAA,CARA,YAAA,CACA,qBAAA,CAQA,sCAAA,CAFA,iBAAA,CAFA,8BCMF,CDAE,4EACE,mEAAA,CAEA,cAAA,CADA,YCGJ,CCjBE,yCAeE,gDAPU,CAEZ,YDWF,CDDE,2IAEE,oEAAA,CEAF,eAAA,CADA,YDKF,CDCE,oJAEE,oEAAA,CEPF,eAAA,CADA,YDWF,CDEE,gEAEE,kBAAA,CADA,kCCCJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clickableCard": "jut__ClickableCard__clickableCard",
	"disabled": "jut__ClickableCard__disabled",
	"activeKeypress": "jut__ClickableCard__activeKeypress"
};
export default ___CSS_LOADER_EXPORT___;
