// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__TreeNodeCollapseAll__button{align-self:flex-start;background:transparent;font-weight:var(--GW-FONT-WEIGHT-BOLD);margin:0 var(--GW-SPACING-4) var(--GW-SPACING-3);padding:0 var(--GW-SPACING-3)}", "",{"version":3,"sources":["webpack://./../../../src/TreeView/TreeNodeCollapseAll.module.scss","webpack://./../../platform/@jutro/components/TreeView/TreeNodeCollapseAll.module.css"],"names":[],"mappings":"AAAA,kCACE,qBAAA,CACA,sBAAA,CACA,sCAAA,CACA,gDAAA,CACA,6BCCF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "jut__TreeNodeCollapseAll__button"
};
export default ___CSS_LOADER_EXPORT___;
