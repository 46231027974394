// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__Header__header{background-color:var(--GW-CONSUMER-HEADER-BACKGROUND-COLOR);border-bottom:4px solid var(--GW-CONSUMER-HEADER-BORDER-BOTTOM-COLOR);box-shadow:var(--GW-SHADOW-1);height:var(--GW-HEADER-HEIGHT);padding:0;z-index:var(--GW-Z-INDEX-4)}@media (max-width:580px){.jut__Header__header{height:var(--GW-CONSUMER-HEADER-PHONE-HEIGHT)}}.jut__Header__headerContent{align-items:center;display:flex;height:100%}.jut__Header__positionSticky{left:auto;position:sticky;right:0;top:0}.jut__Header__headerContainer{display:flex;justify-content:space-between;margin:0 var(--GW-LAYOUT-4)}.jut__Header__headerContainer.jut__Header__fluid{margin:0}@media (min-width:769px){.jut__Header__headerContainer{margin-left:var(--GW-LAYOUT-6);margin-right:var(--GW-LAYOUT-6)}}", "",{"version":3,"sources":["webpack://./../../../../src/internal/Header/Header.module.scss","webpack://./../../platform/@jutro/router/internal/Header/Header.module.css","webpack://./../../../../../../node_modules/@jutro/theme/assets/sass/mixins/breakpoints-mixins.scss"],"names":[],"mappings":"AAEA,qBAIE,2DAAA,CACA,qEAAA,CAFA,6BAAA,CAGA,8BAAA,CALA,SAAA,CACA,2BCMF,CCoCI,yBF5CJ,qBASI,6CCGF,CACF,CDAA,4BAGE,kBAAA,CADA,YAAA,CADA,WCKF,CDAA,6BAGE,SAAA,CAFA,eAAA,CAGA,OAAA,CAFA,KCKF,CDAA,8BACE,YAAA,CACA,6BAAA,CACA,2BCGF,CDDE,iDACE,QCGJ,CCJI,yBFLJ,8BAUI,8BAAA,CACA,+BCGF,CACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "jut__Header__header",
	"headerContent": "jut__Header__headerContent",
	"positionSticky": "jut__Header__positionSticky",
	"headerContainer": "jut__Header__headerContainer",
	"fluid": "jut__Header__fluid"
};
export default ___CSS_LOADER_EXPORT___;
