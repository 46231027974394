// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__ModalFooter__modalFooter{background-color:var(--GW-MODAL-FOOTER-BACKGROUND-COLOR);padding:var(--GW-MODAL-FOOTER-PADDING)}.jut__ModalFooter__modalFooterFlexLayout{height:100%}.jut__ModalFooter__modalFooterFlexLayout>*{white-space:pre-wrap}@media (max-width:580px){.jut__ModalFooter__modalFooterFlexLayout>*{flex-grow:1}}", "",{"version":3,"sources":["webpack://./../../../../../src/widgets/modal-next/ModalFooter/ModalFooter.module.scss","webpack://./../../platform/@jutro/components/widgets/modal-next/ModalFooter/ModalFooter.module.css","webpack://./../../../../../../node_modules/@jutro/theme/assets/sass/mixins/breakpoints-mixins.scss"],"names":[],"mappings":"AAEA,+BACE,wDAAA,CACA,sCCEF,CDCA,yCACE,WCEF,CDAE,2CAKE,oBCFJ,CCiCI,yBFpCF,2CAEI,WCKJ,CACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalFooter": "jut__ModalFooter__modalFooter",
	"modalFooterFlexLayout": "jut__ModalFooter__modalFooterFlexLayout"
};
export default ___CSS_LOADER_EXPORT___;
