// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__ExpandableRow__expandableContent{padding-left:var(--GW-SPACING-4);padding-right:var(--GW-SPACING-4);white-space:nowrap}.jut__ExpandableRow__noTdBorder{display:none}.jut__ExpandableRow__expanderCell{padding:0}.jut__ExpandableRow__emptyContent{padding-bottom:var(--GW-SPACING-4);padding-top:var(--GW-SPACING-4)}", "",{"version":3,"sources":["webpack://./../../../../../../src/dataview/features/expandable/ExpandableRow/ExpandableRow.module.scss","webpack://./../../platform/@jutro/legacy/dataview/features/expandable/ExpandableRow/ExpandableRow.module.css"],"names":[],"mappings":"AAAA,uCACE,gCAAA,CACA,iCAAA,CACA,kBCCF,CDEA,gCACE,YCCF,CDEA,kCACE,SCCF,CDEA,kCAEE,kCAAA,CADA,+BCEF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"expandableContent": "jut__ExpandableRow__expandableContent",
	"noTdBorder": "jut__ExpandableRow__noTdBorder",
	"expanderCell": "jut__ExpandableRow__expanderCell",
	"emptyContent": "jut__ExpandableRow__emptyContent"
};
export default ___CSS_LOADER_EXPORT___;
