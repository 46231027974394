// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__PhaseProgressBar__phaseProgressBar{counter-reset:step;display:flex;flex-direction:column;margin-bottom:var(--GW-SPACING-4);width:100%}.jut__PhaseProgressBar__phases{display:flex;justify-content:space-evenly;width:100%}", "",{"version":3,"sources":["webpack://./../../../../../src/widgets/ProgressIndicator/PhaseProgress/PhaseProgressBar.module.scss","webpack://./../../platform/@jutro/components/widgets/ProgressIndicator/PhaseProgress/PhaseProgressBar.module.css"],"names":[],"mappings":"AAAA,yCAGE,kBAAA,CAFA,YAAA,CAIA,qBAAA,CADA,iCAAA,CAFA,UCIF,CDEA,+BACE,YAAA,CACA,4BAAA,CACA,UCCF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"phaseProgressBar": "jut__PhaseProgressBar__phaseProgressBar",
	"phases": "jut__PhaseProgressBar__phases"
};
export default ___CSS_LOADER_EXPORT___;
