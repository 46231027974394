// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__HeaderActions__headerActions{align-items:center;display:flex;justify-content:right;margin-left:0}.jut__HeaderActions__headerActions>:not(:last-child){margin-right:var(--GW-SPACING-5)}", "",{"version":3,"sources":["webpack://./../../../../src/components/HeaderActions/HeaderActions.module.scss","webpack://./../../platform/@jutro/legacy/components/HeaderActions/HeaderActions.module.css"],"names":[],"mappings":"AAEA,mCAIE,kBAAA,CAHA,YAAA,CAEA,qBAAA,CADA,aCIF,CDAE,qDACE,gCCEJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerActions": "jut__HeaderActions__headerActions"
};
export default ___CSS_LOADER_EXPORT___;
