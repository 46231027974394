// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__typography__labelMedium{font-family:var(--JDS-FONT-LABEL-MEDIUM-FONT-FAMILY-INTERNAL);font-size:var(--JDS-FONT-LABEL-MEDIUM-FONT-SIZE-INTERNAL);font-weight:var(--JDS-FONT-LABEL-MEDIUM-FONT-WEIGHT-INTERNAL);letter-spacing:var(--JDS-FONT-LABEL-MEDIUM-LETTER-SPACING-INTERNAL);line-height:var(--JDS-FONT-LABEL-MEDIUM-LINE-HEIGHT-INTERNAL);-webkit-text-decoration:var(--JDS-FONT-LABEL-MEDIUM-TEXT-DECORATION-INTERNAL);text-decoration:var(--JDS-FONT-LABEL-MEDIUM-TEXT-DECORATION-INTERNAL);text-transform:var(--JDS-FONT-LABEL-MEDIUM-TEXT-CASE-INTERNAL)}", "",{"version":3,"sources":["webpack://./../../../../src/internal/styles/typography.module.scss","webpack://./../../platform/@jutro/components/internal/styles/typography.module.css"],"names":[],"mappings":"AAAA,8BACE,6DAAA,CACA,yDAAA,CACA,6DAAA,CAIA,mEAAA,CAHA,6DAAA,CACA,6EAAA,CAAA,qEAAA,CACA,8DCEF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelMedium": "jut__typography__labelMedium"
};
export default ___CSS_LOADER_EXPORT___;
