// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__AddressForm__addressFieldLabel{white-space:nowrap}", "",{"version":3,"sources":["webpack://./../../../../src/address/addressForm/AddressForm.module.scss","webpack://./../../platform/@jutro/legacy/address/addressForm/AddressForm.module.css"],"names":[],"mappings":"AAAA,qCACE,kBCCF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addressFieldLabel": "jut__AddressForm__addressFieldLabel"
};
export default ___CSS_LOADER_EXPORT___;
