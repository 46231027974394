// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__ModalBody__modalBody{overflow:auto;overflow-wrap:break-word;padding:var(--GW-MODAL-BODY-PADDING);white-space:pre-wrap}.jut__ModalBody__modalBody.jut__ModalBody__hasVerticalScrollbar{box-shadow:inset 0 1px 1px 0 rgba(50,63,79,.16),inset 0 -2px 4px 0 rgba(50,63,79,.16)}", "",{"version":3,"sources":["webpack://./../../../../../src/widgets/modal-next/ModalBody/ModalBody.module.scss","webpack://./../../platform/@jutro/components/widgets/modal-next/ModalBody/ModalBody.module.css"],"names":[],"mappings":"AAAA,2BACE,aAAA,CAGA,wBAAA,CAFA,oCAAA,CACA,oBCEF,CDCE,gEACE,qFCCJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBody": "jut__ModalBody__modalBody",
	"hasVerticalScrollbar": "jut__ModalBody__hasVerticalScrollbar"
};
export default ___CSS_LOADER_EXPORT___;
