// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__Chevron__chevron{display:block;outline:none;transform-origin:center;transition:transform var(--GW-ANIMATION-DURATION)}.jut__Chevron__open{transform:rotate(90deg)}", "",{"version":3,"sources":["webpack://./../../../../src/widgets/chevron/Chevron.module.scss","webpack://./../../platform/@jutro/components/widgets/chevron/Chevron.module.css"],"names":[],"mappings":"AAEA,uBACE,aAAA,CAGA,YAAA,CADA,uBAAA,CADA,iDCIF,CDCA,oBACE,uBCEF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chevron": "jut__Chevron__chevron",
	"open": "jut__Chevron__open"
};
export default ___CSS_LOADER_EXPORT___;
