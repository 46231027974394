// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__PageSwitcherSummary__screenReaderOnly{border:0;clip:rect(0,0,0,0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}", "",{"version":3,"sources":["webpack://./../../../../../../src/dataview/features/pagination/Pagination/PageSwitcher/PageSwitcherSummary.module.scss","webpack://./../../../../../../node_modules/@jutro/theme/assets/sass/mixins/common-mixins.scss","webpack://./../../platform/@jutro/legacy/dataview/features/pagination/Pagination/PageSwitcher/PageSwitcherSummary.module.css"],"names":[],"mappings":"AAEA,4CC4BE,QAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CACA,SAAA,CACA,iBAAA,CACA,SCzBF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"screenReaderOnly": "jut__PageSwitcherSummary__screenReaderOnly"
};
export default ___CSS_LOADER_EXPORT___;
