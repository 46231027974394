// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__FooterNavBar__navBar{display:flex;flex-flow:row wrap;padding:.75rem 0}@media (max-width:1024px){.jut__FooterNavBar__navBar{padding:.25rem 0}}.jut__FooterNavBar__navItem{display:flex;padding-right:2rem}@media (max-width:580px){.jut__FooterNavBar__navItem{padding-right:1.5rem}}.jut__FooterNavBar__navItem .jut__FooterNavBar__navLink{border:1px solid transparent;border-radius:var(--GW-BORDER-RADIUS);cursor:pointer;padding:.125rem}.jut__FooterNavBar__navItem .jut__FooterNavBar__navLink:hover{opacity:.8;text-decoration:underline}.jut__FooterNavBar__navItem .jut__FooterNavBar__navLink:focus{border-color:var(--GW-FOOTER-LINK-BORDER-COLOR-FOCUS);box-shadow:0 0 0 2px var(--GW-FOCUS-COLOR-DARK);outline:none}", "",{"version":3,"sources":["webpack://./../../../../src/components/Footer/FooterNavBar.module.scss","webpack://./../../platform/@jutro/legacy/components/Footer/FooterNavBar.module.css","webpack://./../../../../../../node_modules/@jutro/theme/assets/sass/mixins/breakpoints-mixins.scss","webpack://./../../../../../../node_modules/@jutro/theme/assets/sass/mixins/common-mixins.scss"],"names":[],"mappings":"AAEA,2BACE,YAAA,CACA,kBAAA,CACA,gBCEF,CCuCI,0BF5CJ,2BAMI,gBCGF,CACF,CDAA,4BACE,YAAA,CACA,kBCGF,CC6BI,yBFlCJ,4BAKI,oBCIF,CACF,CDDA,wDAIE,4BAAA,CADA,qCAAA,CAFA,cAAA,CACA,eCMF,CDFE,8DACE,UAAA,CACA,yBCIJ,CE/BE,8DH+BE,qDAAA,CGhBA,+CAPU,CAEZ,YF0BF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navBar": "jut__FooterNavBar__navBar",
	"navItem": "jut__FooterNavBar__navItem",
	"navLink": "jut__FooterNavBar__navLink"
};
export default ___CSS_LOADER_EXPORT___;
