// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__ActionTitleBar__actionTitleBar.jut__ActionTitleBar__actionTitleBarContainer{align-items:center;border-bottom:1px solid var(--GW-BORDER-COLOR);display:flex;justify-content:space-between;margin-bottom:var(--GW-LAYOUT-3);padding-bottom:var(--GW-LAYOUT-3)}.jut__ActionTitleBar__actionTitleBar .jut__ActionTitleBar__titleContainer{overflow:hidden}.jut__ActionTitleBar__actionTitleBar .jut__ActionTitleBar__title{align-self:center;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.jut__ActionTitleBar__actionTitleBar .jut__ActionTitleBar__titleElement{height:100%;min-height:var(--GW-BUTTON-HEIGHT)}.jut__ActionTitleBar__actionTitleBar .jut__ActionTitleBar__toolbar{flex:0 0 auto}", "",{"version":3,"sources":["webpack://./../../../../../src/components/settingsCards/titleBars/ActionTitleBar.module.scss","webpack://./../../platform/@jutro/legacy/components/settingsCards/titleBars/ActionTitleBar.module.css"],"names":[],"mappings":"AACE,kFAGE,kBAAA,CACA,8CAAA,CAHA,YAAA,CACA,6BAAA,CAIA,gCAAA,CADA,iCCCJ,CDGE,0EACE,eCDJ,CDIE,iEACE,iBAAA,CAGA,eAAA,CAFA,sBAAA,CACA,kBCDJ,CDKE,wEAEE,WAAA,CADA,kCCFJ,CDME,mEACE,aCJJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionTitleBar": "jut__ActionTitleBar__actionTitleBar",
	"actionTitleBarContainer": "jut__ActionTitleBar__actionTitleBarContainer",
	"titleContainer": "jut__ActionTitleBar__titleContainer",
	"title": "jut__ActionTitleBar__title",
	"titleElement": "jut__ActionTitleBar__titleElement",
	"toolbar": "jut__ActionTitleBar__toolbar"
};
export default ___CSS_LOADER_EXPORT___;
