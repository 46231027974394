// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__RadioButton__fieldLabelContainer{justify-content:normal}.jut__RadioButton__fieldLabelContainer span{vertical-align:top}.jut__RadioButton__horizontal{display:flex;flex-wrap:wrap}.jut__RadioButton__horizontal .jut__RadioButton__radioButton{align-items:normal;padding-right:var(--GW-SPACING-5);padding-top:0}.jut__RadioButton__horizontal .jut__RadioButton__radioButton .jut__RadioButton__secondaryText{display:inline-flex;padding-left:0}.jut__RadioButton__horizontal .jut__RadioButton__radioButton:last-child{padding-right:0}.jut__RadioButton__horizontal .jut__RadioButton__label{display:flex}", "",{"version":3,"sources":["webpack://./../../../../src/components/RadioButtonField/RadioButton.module.scss","webpack://./../../platform/@jutro/legacy/components/RadioButtonField/RadioButton.module.css"],"names":[],"mappings":"AAAA,uCACE,sBCCF,CDCE,4CACE,kBCCJ,CDGA,8BACE,YAAA,CACA,cCAF,CDEE,6DACE,kBAAA,CAEA,iCAAA,CADA,aCCJ,CDEI,8FACE,mBAAA,CACA,cCAN,CDGI,wEACE,eCDN,CDKE,uDACE,YCHJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldLabelContainer": "jut__RadioButton__fieldLabelContainer",
	"horizontal": "jut__RadioButton__horizontal",
	"radioButton": "jut__RadioButton__radioButton",
	"secondaryText": "jut__RadioButton__secondaryText",
	"label": "jut__RadioButton__label"
};
export default ___CSS_LOADER_EXPORT___;
