// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__NumberInput__numberInput{position:relative}.jut__NumberInput__numberInput input[type=number]::-webkit-inner-spin-button,.jut__NumberInput__numberInput input[type=number]::-webkit-outer-spin-button{-webkit-appearance:none;appearance:none;margin:0}.jut__NumberInput__numberInput input[type=number]{-webkit-appearance:textfield;-moz-appearance:textfield;appearance:textfield}.jut__NumberInput__numberInput .jut__NumberInput__numberInputButton{cursor:pointer;height:calc(50% - var(--GW-LAYOUT-1) - var(--GW-BORDER-WIDTH));padding:0;position:absolute;right:var(--GW-LAYOUT-3);width:var(--GW-SPACING-5)}.jut__NumberInput__numberInput .jut__NumberInput__numberInputUp{top:var(--GW-LAYOUT-1)}.jut__NumberInput__numberInput .jut__NumberInput__numberInputDown{bottom:var(--GW-LAYOUT-1)}", "",{"version":3,"sources":["webpack://./../../../../src/internal/InputNumberField/NumberInput.module.scss","webpack://./../../platform/@jutro/components/internal/InputNumberField/NumberInput.module.css"],"names":[],"mappings":"AAAA,+BACE,iBCCF,CDCE,0JAEE,uBAAA,CAAA,eAAA,CACA,QCCJ,CDEE,kDACE,4BAAA,CAAA,yBAAA,CAAA,oBCAJ,CDGE,oEAKE,cAAA,CAHA,8DAAA,CAIA,SAAA,CALA,iBAAA,CAGA,wBAAA,CADA,yBCEJ,CDIE,gEACE,sBCFJ,CDKE,kEACE,yBCHJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"numberInput": "jut__NumberInput__numberInput",
	"numberInputButton": "jut__NumberInput__numberInputButton",
	"numberInputUp": "jut__NumberInput__numberInputUp",
	"numberInputDown": "jut__NumberInput__numberInputDown"
};
export default ___CSS_LOADER_EXPORT___;
