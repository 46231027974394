// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__DefaultEditForm__notification{background:var(--GW-COLOR-INFO-BACKGROUND-TINT);border:var(--GW-INLINE-NOTIFICATION-BORDER-WIDTH) solid var(--GW-COLOR-INFO);border-radius:var(--GW-BORDER-RADIUS);display:flex;min-height:var(--GW-INLINE-NOTIFICATION-MIN-HEIGHT);padding:var(--GW-SPACING-3);width:100%}.jut__DefaultEditForm__notification .jut__DefaultEditForm__notificationIcon{color:var(--GW-COLOR-INFO);margin-right:var(--GW-SPACING-3)}.jut__DefaultEditForm__notification .jut__DefaultEditForm__notificationMessage{-moz-column-gap:var(--GW-SPACING-1);column-gap:var(--GW-SPACING-1);display:flex;flex-wrap:wrap}", "",{"version":3,"sources":["webpack://./../../../../../../src/dataview/features/editing/EditForm/DefaultEditForm.module.scss","webpack://./../../platform/@jutro/legacy/dataview/features/editing/EditForm/DefaultEditForm.module.css"],"names":[],"mappings":"AAAA,oCACE,+CAAA,CACA,4EAAA,CACA,qCAAA,CAIA,YAAA,CAFA,mDAAA,CACA,2BAAA,CAFA,UCIF,CDCE,4EACE,0BAAA,CACA,gCCCJ,CDEE,+EAGE,mCAAA,CAAA,8BAAA,CAFA,YAAA,CACA,cCCJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification": "jut__DefaultEditForm__notification",
	"notificationIcon": "jut__DefaultEditForm__notificationIcon",
	"notificationMessage": "jut__DefaultEditForm__notificationMessage"
};
export default ___CSS_LOADER_EXPORT___;
