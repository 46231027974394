// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__TabSet__tabSet{display:flex;flex-direction:column;min-width:0;position:relative;width:100%}.jut__TabSet__tabSet hr.jut__TabSet__divider{border-top:1px solid var(--GW-SEPARATOR-COLOR);margin:0}.jut__TabSet__tabSet :focus,.jut__TabSet__tabSet:focus{outline:none}.jut__TabSet__frame{border:1px solid var(--GW-BORDER-COLOR);border-radius:var(--GW-BORDER-RADIUS)}.jut__TabSet__content{background:var(--GW-TAB-BAR-BACKGROUND-COLOR);font-size:var(--GW-FONT-SIZE-BODY);line-height:var(--GW-LINE-HEIGHT-BODY);margin:0}", "",{"version":3,"sources":["webpack://./../../../../src/widgets/tab/TabSet.module.scss","webpack://./../../platform/@jutro/components/widgets/tab/TabSet.module.css","webpack://./../../../../../../node_modules/@jutro/theme/assets/sass/mixins/typography-mixins.scss"],"names":[],"mappings":"AAEA,qBAIE,YAAA,CACA,qBAAA,CAJA,WAAA,CAEA,iBAAA,CADA,UCKF,CDAE,6CAEE,8CAAA,CADA,QCGJ,CDCE,uDAEE,YCAJ,CDIA,oBACE,uCAAA,CACA,qCCDF,CDIA,sBAEE,6CAAA,CEDA,kCAAA,CACA,sCAAA,CFDA,QCEF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabSet": "jut__TabSet__tabSet",
	"divider": "jut__TabSet__divider",
	"frame": "jut__TabSet__frame",
	"content": "jut__TabSet__content"
};
export default ___CSS_LOADER_EXPORT___;
