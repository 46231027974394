// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__Accordion__accordion{display:block}", "",{"version":3,"sources":["webpack://./../../../src/Accordion/Accordion.module.scss","webpack://./../../platform/@jutro/components/Accordion/Accordion.module.css"],"names":[],"mappings":"AAAA,2BACE,aCCF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordion": "jut__Accordion__accordion"
};
export default ___CSS_LOADER_EXPORT___;
