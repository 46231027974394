// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__Icon__empty{display:none!important;margin:0}.jut__Icon__svgIcon{font-size:1.25rem;fill:currentcolor;box-sizing:content-box;height:1em;width:1em}.mir.jut__Icon__iconSize1{font-size:var(--GW-ICON-FONT-SIZE-1);line-height:var(--GW-ICON-LINE-HEIGHT);min-height:1em;min-width:1em}.mir.jut__Icon__iconSize2{font-size:var(--GW-ICON-FONT-SIZE-2);line-height:var(--GW-ICON-LINE-HEIGHT);min-height:1em;min-width:1em}.mir.jut__Icon__iconSize3{font-size:var(--GW-ICON-FONT-SIZE-3);line-height:var(--GW-ICON-LINE-HEIGHT);min-height:1em;min-width:1em}.jut__Icon__iconSize1{font-size:var(--GW-ICON-FONT-SIZE-1)}.jut__Icon__iconSize1,.jut__Icon__iconSize2{line-height:var(--GW-ICON-LINE-HEIGHT);min-height:1em;min-width:1em}.jut__Icon__iconSize2{font-size:var(--GW-ICON-FONT-SIZE-2)}.jut__Icon__iconSize3{font-size:var(--GW-ICON-FONT-SIZE-3);line-height:var(--GW-ICON-LINE-HEIGHT);min-height:1em;min-width:1em}", "",{"version":3,"sources":["webpack://./../../../../src/widgets/Icon/Icon.module.scss","webpack://./../../platform/@jutro/components/widgets/Icon/Icon.module.css","webpack://./../../../../../../node_modules/@jutro/theme/assets/sass/mixins/typography-mixins.scss"],"names":[],"mappings":"AAEA,kBAGE,sBAAA,CACA,QCAF,CDKA,oBACE,iBAHU,CAIV,iBAAA,CAGA,sBAAA,CAFA,UAAA,CACA,SCDF,CDME,0BEoCA,oCAAA,CACA,sCAAA,CFpCE,cAAA,CACA,aCDJ,CDME,0BEkCA,oCAAA,CACA,sCAAA,CFlCE,cAAA,CACA,aCFJ,CDOE,0BEgCA,oCAAA,CACA,sCAAA,CFhCE,cAAA,CACA,aCHJ,CDSA,sBEcE,oCDhBF,CDSA,4CEQE,sCAAA,CFdA,cAAA,CACA,aCGF,CDEA,sBEYE,oCDdF,CDSA,sBEUE,oCAAA,CACA,sCAAA,CFVA,cAAA,CACA,aCJF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"empty": "jut__Icon__empty",
	"svgIcon": "jut__Icon__svgIcon",
	"iconSize1": "jut__Icon__iconSize1",
	"iconSize2": "jut__Icon__iconSize2",
	"iconSize3": "jut__Icon__iconSize3"
};
export default ___CSS_LOADER_EXPORT___;
