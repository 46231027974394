// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__ContextSwitcher__contextSwitcherMenu{font-size:var(--GW-FONT-SIZE-BODY);line-height:var(--GW-LINE-HEIGHT-BODY);margin:0;max-height:288px;overflow-y:auto;padding:.125rem 0;width:var(--GW-CONTEXT-SWITCHER-WIDTH)}.jut__ContextSwitcher__contextSwitcherButton{align-items:center;display:flex;font-size:var(--GW-FONT-SIZE-SUBTEXT);height:var(--GW-CONTEXT-SWITCHER-HEIGHT);justify-content:space-between;line-height:var(--GW-LINE-HEIGHT-SUBTEXT);padding:0 1rem;width:var(--GW-CONTEXT-SWITCHER-WIDTH)}.jut__ContextSwitcher__contextSwitcherButton span{max-width:calc(var(--GW-CONTEXT-SWITCHER-WIDTH) - 3.5rem);overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.jut__ContextSwitcher__activeContext{background-color:var(--GW-CONTEXT-SWITCHER-BACKGROUND-COLOR-ACTIVE)}", "",{"version":3,"sources":["webpack://./../../../src/ContextSwitcher/ContextSwitcher.module.scss","webpack://./../../../../../node_modules/@jutro/theme/assets/sass/mixins/typography-mixins.scss","webpack://./../../platform/@jutro/router/ContextSwitcher/ContextSwitcher.module.css"],"names":[],"mappings":"AAEA,2CCwBE,kCAAA,CACA,sCAAA,CDxBA,QAAA,CAGA,gBAAA,CACA,eAAA,CAHA,iBAAA,CACA,sCEMF,CFCA,6CACE,kBAAA,CACA,YAAA,CCiBA,qCAAA,CDhBA,wCAAA,CACA,6BAAA,CCgBA,yCAAA,CDfA,cAAA,CACA,sCEIF,CFAE,kDACE,yDAAA,CACA,eAAA,CACA,sBAAA,CACA,kBEEJ,CFEA,qCACE,mEECF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contextSwitcherMenu": "jut__ContextSwitcher__contextSwitcherMenu",
	"contextSwitcherButton": "jut__ContextSwitcher__contextSwitcherButton",
	"activeContext": "jut__ContextSwitcher__activeContext"
};
export default ___CSS_LOADER_EXPORT___;
