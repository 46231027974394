// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__DropdownMenuAvatar__dropdownMenuAvatarHeader{align-items:center;display:flex;flex-direction:row;justify-content:flex-start;padding:var(--GW-SPACING-2) var(--GW-SPACING-6) var(--GW-SPACING-1)}.jut__DropdownMenuAvatar__headerText{overflow:hidden;padding-left:var(--GW-SPACING-3)}.jut__DropdownMenuAvatar__headerTitle{color:var(--GW-DROPDOWN-MENU-AVATAR-TITLE-COLOR);font-size:var(--GW-FONT-SIZE-H4);font-weight:var(--GW-FONT-WEIGHT-SEMI-BOLD);line-height:var(--GW-LINE-HEIGHT-H4)}.jut__DropdownMenuAvatar__headerSubtitle,.jut__DropdownMenuAvatar__headerTitle{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.jut__DropdownMenuAvatar__headerSubtitle{color:var(--GW-DROPDOWN-MENU-AVATAR-SUBTITLE-COLOR);font-size:var(--GW-FONT-SIZE-SUBTEXT)}.jut__DropdownMenuAvatar__dropdownMenuAvatarMenu{max-width:var(--GW-DROPDOWN-MENU-AVATAR-WIDTH);min-width:var(--GW-DROPDOWN-MENU-AVATAR-WIDTH);padding-bottom:var(--GW-SPACING-4);padding-top:var(--GW-SPACING-4)}", "",{"version":3,"sources":["webpack://./../../../../src/widgets/DropdownMenu/DropdownMenuAvatar.module.scss","webpack://./../../platform/@jutro/components/widgets/DropdownMenu/DropdownMenuAvatar.module.css","webpack://./../../../../../../node_modules/@jutro/theme/assets/sass/mixins/typography-mixins.scss"],"names":[],"mappings":"AAEA,mDAIE,kBAAA,CAHA,YAAA,CACA,kBAAA,CACA,0BAAA,CAEA,mECEF,CDCA,qCACE,eAAA,CACA,gCCEF,CDCA,sCAEE,gDAAA,CEDA,gCAAA,CFAA,2CAAA,CECA,oCDOF,CDCA,+EANE,eAAA,CACA,sBAAA,CAFA,kBCcF,CDPA,yCAEE,mDAAA,CADA,qCCMF,CDCA,iDAEE,8CAAA,CADA,8CAAA,CAGA,kCAAA,CADA,+BCGF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownMenuAvatarHeader": "jut__DropdownMenuAvatar__dropdownMenuAvatarHeader",
	"headerText": "jut__DropdownMenuAvatar__headerText",
	"headerTitle": "jut__DropdownMenuAvatar__headerTitle",
	"headerSubtitle": "jut__DropdownMenuAvatar__headerSubtitle",
	"dropdownMenuAvatarMenu": "jut__DropdownMenuAvatar__dropdownMenuAvatarMenu"
};
export default ___CSS_LOADER_EXPORT___;
