// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__PhoneNumberInput__input{background-color:transparent;border:none;color:var(--JDS-INPUT-FIELD-TEXT-COLOR-TEXT-INTERNAL);height:100%;outline:none;padding:0;position:relative;width:100%}.jut__PhoneNumberInput__input[disabled]{cursor:not-allowed}.jut__PhoneNumberInput__withDropdown{padding:0}.jut__PhoneNumberInput__withoutLeftPadding{padding-left:0}.jut__PhoneNumberInput__inputOverrides{align-items:center;display:flex;gap:var(--JDS-INPUT-FIELD-GAP-INTERNAL);position:relative}.jut__PhoneNumberInput__itiOverrides{height:100%}.jut__PhoneNumberInput__itiOverrides .jut__PhoneNumberInput__input[type=tel]{padding:0}.jut__PhoneNumberInput__inputWrapper{height:100%;width:100%}.jut__PhoneNumberInput__prefix{align-items:center;display:flex}.jut__PhoneNumberInput__prefixColor{color:var(--JDS-COLOR-TEXT-SUBTLE-INTERNAL)}.jut__PhoneNumberInput__prefixColorDisabled{color:var(--JDS-INPUT-FIELD-TEXT-COLOR-TEXT-INTERNAL)}", "",{"version":3,"sources":["webpack://./../../../../src/fields/PhoneInput/PhoneNumberInput.module.scss","webpack://./../../platform/@jutro/components/fields/PhoneInput/PhoneNumberInput.module.css"],"names":[],"mappings":"AAAA,8BAME,4BAAA,CAHA,WAAA,CAIA,qDAAA,CAFA,WAAA,CAHA,YAAA,CAMA,SAAA,CAPA,iBAAA,CAGA,UCKF,CDCE,wCACE,kBCCJ,CDGA,qCACE,SCAF,CDGA,2CACE,cCAF,CDGA,uCAGE,kBAAA,CADA,YAAA,CAEA,uCAAA,CAHA,iBCGF,CDGA,qCACE,WCAF,CDEE,6EACE,SCAJ,CDIA,qCACE,WAAA,CACA,UCDF,CDIA,+BAEE,kBAAA,CADA,YCAF,CDIA,oCACE,2CCDF,CDIA,4CACE,qDCDF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "jut__PhoneNumberInput__input",
	"withDropdown": "jut__PhoneNumberInput__withDropdown",
	"withoutLeftPadding": "jut__PhoneNumberInput__withoutLeftPadding",
	"inputOverrides": "jut__PhoneNumberInput__inputOverrides",
	"itiOverrides": "jut__PhoneNumberInput__itiOverrides",
	"inputWrapper": "jut__PhoneNumberInput__inputWrapper",
	"prefix": "jut__PhoneNumberInput__prefix",
	"prefixColor": "jut__PhoneNumberInput__prefixColor",
	"prefixColorDisabled": "jut__PhoneNumberInput__prefixColorDisabled"
};
export default ___CSS_LOADER_EXPORT___;
