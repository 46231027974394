// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__LoadMore__loadMore{align-items:center;display:flex;flex-direction:column;justify-content:center}", "",{"version":3,"sources":["webpack://./../../../../../../src/dataview/features/pagination/LoadMore/LoadMore.module.scss","webpack://./../../platform/@jutro/legacy/dataview/features/pagination/LoadMore/LoadMore.module.css"],"names":[],"mappings":"AAAA,yBAIE,kBAAA,CAHA,YAAA,CACA,qBAAA,CACA,sBCEF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadMore": "jut__LoadMore__loadMore"
};
export default ___CSS_LOADER_EXPORT___;
