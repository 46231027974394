// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__MultipleFilesUploadField__notificationError{margin:var(--GW-SPACING-3);position:absolute;width:calc(100% - var(--GW-SPACING-3)*2);z-index:var(--GW-Z-INDEX-2)}@media only screen and (hover:none) and (pointer:coarse) and (max-device-width:768px){.jut__MultipleFilesUploadField__notificationError{top:0}}.jut__MultipleFilesUploadField__bottomSpacing{margin-bottom:var(--GW-SPACING-3)}@media only screen and (hover:none) and (pointer:coarse) and (max-device-width:768px){.jut__MultipleFilesUploadField__mobileViewDragAndDropArea{border:none;justify-items:left;padding:0}.jut__MultipleFilesUploadField__mobileViewDragAndDropComponents{display:none}}", "",{"version":3,"sources":["webpack://./../../../../../src/internal/FileUploadField/MultipleFilesUploadField/MultipleFilesUploadField.module.scss","webpack://./../../platform/@jutro/components/internal/FileUploadField/MultipleFilesUploadField/MultipleFilesUploadField.module.css"],"names":[],"mappings":"AAEA,kDAGE,0BAAA,CAFA,iBAAA,CAGA,wCAAA,CAFA,2BCIF,CDAE,sFANF,kDAOI,KCGF,CACF,CDAA,8CACE,iCCGF,CDCE,sFADF,0DAII,WAAA,CAFA,kBAAA,CACA,SCIF,CDCF,gEAEI,YCGF,CALF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notificationError": "jut__MultipleFilesUploadField__notificationError",
	"bottomSpacing": "jut__MultipleFilesUploadField__bottomSpacing",
	"mobileViewDragAndDropArea": "jut__MultipleFilesUploadField__mobileViewDragAndDropArea",
	"mobileViewDragAndDropComponents": "jut__MultipleFilesUploadField__mobileViewDragAndDropComponents"
};
export default ___CSS_LOADER_EXPORT___;
