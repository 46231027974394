// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__SimpleProgressBar__simpleProgressBar{counter-reset:step;width:100%}.jut__SimpleProgressBar__progressBar{background-color:var(--GW-PROGRESS-BARS-LEFT-BAR-COLOR);overflow:hidden}.jut__SimpleProgressBar__progressBar,.jut__SimpleProgressBar__progressBar .jut__SimpleProgressBar__progressBarSteps{border-radius:var(--GW-BORDER-RADIUS);min-height:var(--GW-SIMPLE-PROGRESS-BAR-MIN-HEIGHT)}.jut__SimpleProgressBar__progressBar .jut__SimpleProgressBar__progressBarSteps{background-color:var(--GW-PROGRESS-BARS-COMPLETE-COLOR);transform-origin:left;transition:var(--GW-ANIMATION-DURATION)}.jut__SimpleProgressBar__labelContainer{display:flex;justify-content:space-between}.jut__SimpleProgressBar__labelContainerCenter{display:flex;justify-content:center}.jut__SimpleProgressBar__label,.jut__SimpleProgressBar__progressLabel{color:var(--GW-PROGRESS-BARS-STATUS-COLOR);font-size:var(--GW-FONT-SIZE-LABEL);letter-spacing:0;line-height:var(--GW-LINE-HEIGHT-LABEL);margin-bottom:var(--GW-SPACING-1);margin-top:var(--GW-SPACING-2);text-align:center}", "",{"version":3,"sources":["webpack://./../../../src/SimpleProgressBar/SimpleProgressBar.module.scss","webpack://./../../platform/@jutro/components/SimpleProgressBar/SimpleProgressBar.module.css","webpack://./../../../../../node_modules/@jutro/theme/assets/sass/mixins/typography-mixins.scss"],"names":[],"mappings":"AAEA,2CAEE,kBAAA,CADA,UCGF,CDCA,qCAEE,uDAAA,CAEA,eCEF,CDAE,oHAHA,qCAAA,CAFA,mDCYF,CDPE,+EAGE,uDAAA,CAFA,qBAAA,CAGA,uCCGJ,CDEA,wCACE,YAAA,CACA,6BCCF,CDEA,8CACE,YAAA,CACA,sBCCF,CDEA,sEAEE,0CAAA,CEEA,mCAAA,CFDA,gBAAA,CEEA,uCAAA,CFAA,iCAAA,CACA,8BAAA,CAFA,iBCKF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"simpleProgressBar": "jut__SimpleProgressBar__simpleProgressBar",
	"progressBar": "jut__SimpleProgressBar__progressBar",
	"progressBarSteps": "jut__SimpleProgressBar__progressBarSteps",
	"labelContainer": "jut__SimpleProgressBar__labelContainer",
	"labelContainerCenter": "jut__SimpleProgressBar__labelContainerCenter",
	"label": "jut__SimpleProgressBar__label",
	"progressLabel": "jut__SimpleProgressBar__progressLabel"
};
export default ___CSS_LOADER_EXPORT___;
