// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__ListViewItem__listViewItem{background-color:var(--GW-BACKGROUND-COLOR-ELEMENT);border-radius:var(--GW-BORDER-RADIUS);margin-bottom:var(--GW-LAYOUT-2);margin-top:var(--GW-LAYOUT-2)}.jut__ListViewItem__actionsRightAlignment{display:flex;justify-content:space-between}.jut__ListViewItem__actionsBottomAlignment{justify-content:flex-end;padding-bottom:var(--GW-LAYOUT-4)}.jut__ListViewItem__listViewSelectionColumn{align-items:center;display:flex;padding-right:var(--GW-SPACING-5)}.jut__ListViewItem__listViewItemContent{flex-grow:3;margin-left:var(--GW-LAYOUT-4)}.jut__ListViewItem__listViewActions{align-items:center;display:flex;flex-grow:0}.jut__ListViewItem__listViewActions>*{margin-right:var(--GW-LAYOUT-4)}", "",{"version":3,"sources":["webpack://./../../../../../src/dataview/ListView/ListViewItem/ListViewItem.module.scss","webpack://./../../platform/@jutro/legacy/dataview/ListView/ListViewItem/ListViewItem.module.css"],"names":[],"mappings":"AAAA,iCACE,mDAAA,CACA,qCAAA,CAEA,gCAAA,CADA,6BCEF,CDEA,0CACE,YAAA,CACA,6BCCF,CDEA,2CAEE,wBAAA,CADA,iCCEF,CDEA,4CAGE,kBAAA,CADA,YAAA,CADA,iCCGF,CDEA,wCAEE,WAAA,CADA,8BCEF,CDEA,oCAEE,kBAAA,CADA,YAAA,CAEA,WCCF,CDCE,sCACE,+BCCJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listViewItem": "jut__ListViewItem__listViewItem",
	"actionsRightAlignment": "jut__ListViewItem__actionsRightAlignment",
	"actionsBottomAlignment": "jut__ListViewItem__actionsBottomAlignment",
	"listViewSelectionColumn": "jut__ListViewItem__listViewSelectionColumn",
	"listViewItemContent": "jut__ListViewItem__listViewItemContent",
	"listViewActions": "jut__ListViewItem__listViewActions"
};
export default ___CSS_LOADER_EXPORT___;
