// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__StepProgressBar__stepProgressBar{display:flex;margin-bottom:var(--GW-SPACING-4);position:relative;width:100%}.jut__StepProgressBar__vertical.jut__StepProgressBar__stepProgressBar{flex-direction:column}.jut__StepProgressBar__phoneTitle{box-sizing:border-box;overflow:hidden;padding:0 var(--GW-SPACING-1);word-wrap:break-word;bottom:0;font-size:var(--GW-FONT-SIZE-LABEL);line-height:var(--GW-LINE-HEIGHT-LABEL);margin-bottom:var(--GW-SPACING-4);text-align:center}.jut__StepProgressBar__titleDisabled{cursor:not-allowed;opacity:var(--GW-OPACITY-DISABLED)}@media (max-width:580px){.jut__StepProgressBar__stepProgressBar:not(.jut__StepProgressBar__vertical){margin-bottom:0}}", "",{"version":3,"sources":["webpack://./../../../../../src/widgets/ProgressIndicator/StepProgress/StepProgressBar.module.scss","webpack://./../../platform/@jutro/components/widgets/ProgressIndicator/StepProgress/StepProgressBar.module.css","webpack://./../../../../../../node_modules/@jutro/theme/assets/sass/mixins/typography-mixins.scss","webpack://./../../../../../../node_modules/@jutro/theme/assets/sass/mixins/breakpoints-mixins.scss"],"names":[],"mappings":"AAEA,uCAEE,YAAA,CAEA,iCAAA,CAHA,iBAAA,CAEA,UCGF,CDCA,sEACE,qBCEF,CDCA,kCAEE,qBAAA,CADA,eAAA,CAEA,6BAAA,CACA,oBAAA,CAEA,QAAA,CEiBA,mCAAA,CACA,uCAAA,CFjBA,iCAAA,CAFA,iBCMF,CDCA,qCAEE,kBAAA,CADA,kCCGF,CEiBI,yBHfF,4EACE,eCEF,CACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepProgressBar": "jut__StepProgressBar__stepProgressBar",
	"vertical": "jut__StepProgressBar__vertical",
	"phoneTitle": "jut__StepProgressBar__phoneTitle",
	"titleDisabled": "jut__StepProgressBar__titleDisabled"
};
export default ___CSS_LOADER_EXPORT___;
