// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__NavigationInternal__pagination{padding:var(--GW-LAYOUT-4) var(--GW-LAYOUT-1) 0 var(--GW-LAYOUT-1)}", "",{"version":3,"sources":["webpack://./../../../../../src/dataview/features/pagination/NavigationInternal.module.scss","webpack://./../../platform/@jutro/legacy/dataview/features/pagination/NavigationInternal.module.css"],"names":[],"mappings":"AAAA,qCACE,kECCF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": "jut__NavigationInternal__pagination"
};
export default ___CSS_LOADER_EXPORT___;
