// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__MultipleValue__valueMultiple{align-items:center;display:flex;flex-grow:0;flex-shrink:0;gap:var(--JDS-INPUT-FIELD-MULTISELECT-GAP-INTERNAL);max-width:100%;overflow:hidden}.jut__MultipleValue__valueExpanded{flex-wrap:wrap;padding:5px 0}", "",{"version":3,"sources":["webpack://./../../../../../../src/fields/dropdowns/internal/MultipleValue/MultipleValue.module.scss","webpack://./../../platform/@jutro/components/fields/dropdowns/internal/MultipleValue/MultipleValue.module.css"],"names":[],"mappings":"AAEA,mCAEE,kBAAA,CADA,YAAA,CAEA,WAAA,CACA,aAAA,CAEA,mDAAA,CADA,cAAA,CAEA,eCDF,CDIA,mCACE,cAAA,CACA,aCDF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"valueMultiple": "jut__MultipleValue__valueMultiple",
	"valueExpanded": "jut__MultipleValue__valueExpanded"
};
export default ___CSS_LOADER_EXPORT___;
