// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__ListView__listView{background-color:var(--GW-CARD-BACKGROUND-COLOR);color:var(--GW-CARD-COLOR);display:flex;flex-direction:column}.jut__ListView__listViewItemsWrapper{overflow:hidden auto}", "",{"version":3,"sources":["webpack://./../../../../src/dataview/ListView/ListView.module.scss","webpack://./../../platform/@jutro/legacy/dataview/ListView/ListView.module.css"],"names":[],"mappings":"AAAA,yBAIE,gDAAA,CADA,0BAAA,CAFA,YAAA,CACA,qBCGF,CDEA,qCACE,oBCCF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listView": "jut__ListView__listView",
	"listViewItemsWrapper": "jut__ListView__listViewItemsWrapper"
};
export default ___CSS_LOADER_EXPORT___;
