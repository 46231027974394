// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__GlobalizationChooser__globalizationChooser{background-color:var(--GW-GLOBALIZATION-CHOOSER-BACKGROUND-COLOR);border:1px solid var(--GW-BORDER-COLOR);border-radius:var(--GW-BORDER-RADIUS);color:var(--GW-GLOBALIZATION-CHOOSER-COLOR);display:inline-block;height:var(--GW-GLOBALIZATION-CHOOSER-HEIGHT);min-width:var(--GW-GLOBALIZATION-CHOOSER-MIN-WIDTH);padding:var(--GW-SPACING-2) var(--GW-SPACING-3);width:auto}", "",{"version":3,"sources":["webpack://./../../../../src/widgets/GlobalizationChooser/GlobalizationChooser.module.scss","webpack://./../../platform/@jutro/components/widgets/GlobalizationChooser/GlobalizationChooser.module.css"],"names":[],"mappings":"AAAA,iDAQE,iEAAA,CAFA,uCAAA,CACA,qCAAA,CAEA,2CAAA,CAJA,oBAAA,CAJA,6CAAA,CAEA,mDAAA,CACA,+CAAA,CAFA,UCQF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"globalizationChooser": "jut__GlobalizationChooser__globalizationChooser"
};
export default ___CSS_LOADER_EXPORT___;
