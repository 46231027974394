// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__CheckboxGroupField__checkboxGroup{display:flex;margin-top:var(--GW-SPACING-3)}.jut__CheckboxGroupField__checkboxGroup:first-child{margin-top:0}.jut__CheckboxGroupField__tooltip{bottom:auto;position:absolute;top:0}.jut__CheckboxGroupField__fieldLabelContainer{justify-content:normal}.jut__CheckboxGroupField__fieldLabelContainer span{line-height:1rem;vertical-align:top}.jut__CheckboxGroupField__horizontal{display:flex;flex-wrap:wrap}.jut__CheckboxGroupField__horizontal .jut__CheckboxGroupField__checkboxGroup{margin-top:0;padding-bottom:var(--GW-SPACING-3);padding-right:var(--GW-SPACING-5);padding-top:0}.jut__CheckboxGroupField__horizontal .jut__CheckboxGroupField__checkboxGroup:last-child{padding-right:0}.jut__CheckboxGroupField__horizontal .jut__CheckboxGroupField__labelContainer{align-items:baseline;flex-direction:row}", "",{"version":3,"sources":["webpack://./../../../../src/components/CheckboxGroupField/CheckboxGroupField.module.scss","webpack://./../../platform/@jutro/legacy/components/CheckboxGroupField/CheckboxGroupField.module.css"],"names":[],"mappings":"AAAA,wCAEE,YAAA,CADA,8BCEF,CDCE,oDACE,YCCJ,CDGA,kCAGE,WAAA,CAFA,iBAAA,CACA,KCCF,CDGA,8CACE,sBCAF,CDEE,mDACE,gBAAA,CACA,kBCAJ,CDIA,qCACE,YAAA,CACA,cCDF,CDGE,6EAEE,YAAA,CAEA,kCAAA,CADA,iCAAA,CAFA,aCEJ,CDGI,wFACE,eCDN,CDKE,8EAEE,oBAAA,CADA,kBCFJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxGroup": "jut__CheckboxGroupField__checkboxGroup",
	"tooltip": "jut__CheckboxGroupField__tooltip",
	"fieldLabelContainer": "jut__CheckboxGroupField__fieldLabelContainer",
	"horizontal": "jut__CheckboxGroupField__horizontal",
	"labelContainer": "jut__CheckboxGroupField__labelContainer"
};
export default ___CSS_LOADER_EXPORT___;
