// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__SliderIndicatorsContainer__min{margin-right:var(--GW-SPACING-4)}.jut__SliderIndicatorsContainer__max{margin-left:var(--GW-SPACING-4)}", "",{"version":3,"sources":["webpack://./../../../../../src/widgets/inputs/Slider/SliderIndicatorsContainer.module.scss","webpack://./../../platform/@jutro/components/widgets/inputs/Slider/SliderIndicatorsContainer.module.css"],"names":[],"mappings":"AAAA,qCACE,gCCCF,CDEA,qCACE,+BCCF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"min": "jut__SliderIndicatorsContainer__min",
	"max": "jut__SliderIndicatorsContainer__max"
};
export default ___CSS_LOADER_EXPORT___;
