// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__PageSwitcher__pageSwitcher .jut__PageSwitcher__button{margin-left:0;margin-right:var(--GW-SPACING-2);padding:0}.jut__PageSwitcher__pageSwitcher .jut__PageSwitcher__button svg{font-size:var(--GW-DATA-VIEWS-PAGINATION-ICON-SIZE)}.jut__PageSwitcher__pageSwitcher .jut__PageSwitcher__button:last-child{margin-right:0}", "",{"version":3,"sources":["webpack://./../../../../../../src/dataview/features/pagination/Pagination/PageSwitcher/PageSwitcher.module.scss","webpack://./../../platform/@jutro/legacy/dataview/features/pagination/Pagination/PageSwitcher/PageSwitcher.module.css"],"names":[],"mappings":"AACE,4DACE,aAAA,CAEA,gCAAA,CADA,SCCJ,CDEI,gEACE,mDCAN,CDGI,uEACE,cCDN","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageSwitcher": "jut__PageSwitcher__pageSwitcher",
	"button": "jut__PageSwitcher__button"
};
export default ___CSS_LOADER_EXPORT___;
