// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__StepperField__stepper{align-items:center;display:flex}.jut__StepperField__hidden{border:0;clip:rect(0,0,0,0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}.jut__StepperField__input{-webkit-appearance:textfield;-moz-appearance:textfield;appearance:textfield;font-size:var(--GW-FONT-SIZE-INPUT);line-height:var(--GW-LINE-HEIGHT-INPUT);margin-left:var(--GW-SPACING-2);margin-right:var(--GW-SPACING-2);padding:0;text-align:center;width:var(--GW-STEPPER-FIELD-WIDTH)}.jut__StepperField__input:focus{background-color:var(--GW-FIELD-COMPONENT-BACKGROUND-COLOR)}.jut__StepperField__input:not(:focus){border:none;text-overflow:ellipsis}.jut__StepperField__input::-webkit-inner-spin-button,.jut__StepperField__input::-webkit-outer-spin-button{-webkit-appearance:none;appearance:none;margin:0}", "",{"version":3,"sources":["webpack://./../../../../../src/widgets/inputs/StepperField/StepperField.module.scss","webpack://./../../platform/@jutro/components/widgets/inputs/StepperField/StepperField.module.css","webpack://./../../../../../../node_modules/@jutro/theme/assets/sass/mixins/common-mixins.scss","webpack://./../../../../../../node_modules/@jutro/theme/assets/sass/mixins/typography-mixins.scss"],"names":[],"mappings":"AAEA,4BAEE,kBAAA,CADA,YCGF,CDCA,2BEuBE,QAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CACA,SAAA,CACA,iBAAA,CACA,SDpBF,CDNA,0BAME,4BAAA,CAAA,yBAAA,CAAA,oBAAA,CG6BA,mCAAA,CACA,uCAAA,CH/BA,+BAAA,CADA,gCAAA,CAFA,SAAA,CADA,iBAAA,CAEA,mCCcF,CDPE,gCACE,2DCSJ,CDNE,sCACE,WAAA,CACA,sBCQJ,CDLE,0GAEE,uBAAA,CAAA,eAAA,CACA,QCMJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepper": "jut__StepperField__stepper",
	"hidden": "jut__StepperField__hidden",
	"input": "jut__StepperField__input"
};
export default ___CSS_LOADER_EXPORT___;
