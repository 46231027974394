// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__PageLoader__pageLoader{background-color:var(--GW-BACKGROUND-COLOR);height:100%;position:fixed;width:100%;z-index:var(--GW-Z-INDEX-4)}html:not(.jut__PageLoader__appLoading) .jut__PageLoader__pageLoader{display:none}.jut__PageLoader__appLoading{content:normal}", "",{"version":3,"sources":["webpack://./../../../../src/components/PageLoader/PageLoader.module.scss","webpack://./../../platform/@jutro/legacy/components/PageLoader/PageLoader.module.css"],"names":[],"mappings":"AAAA,6BAIE,2CAAA,CAHA,WAAA,CAIA,cAAA,CAHA,UAAA,CACA,2BCGF,CDCE,oEACE,YCCJ,CDGA,6BACE,cCAF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageLoader": "jut__PageLoader__pageLoader",
	"appLoading": "jut__PageLoader__appLoading"
};
export default ___CSS_LOADER_EXPORT___;
