// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__HelpPopover__HelpPopover{max-height:var(--GW-HELP-POPOVER-MAX-HEIGHT);width:var(--GW-HELP-POPOVER-WIDTH);z-index:var(--GW-HELP-POPOVER-Z-INDEX)}.jut__HelpPopover__popoverContainer{width:100%}.jut__HelpPopover__content{height:100%;max-height:calc(var(--GW-HELP-POPOVER-MAX-HEIGHT) - var(--GW-HELP-POPOVER-FOOTER-HEIGHT)*2);overflow-y:auto;padding:var(--GW-SPACING-4) var(--GW-SPACING-6)}.jut__HelpPopover__footer{height:var(--GW-HELP-POPOVER-FOOTER-HEIGHT);text-align:center}", "",{"version":3,"sources":["webpack://./../../../../src/internal/HelpPopover/HelpPopover.module.scss","webpack://./../../platform/@jutro/router/internal/HelpPopover/HelpPopover.module.css"],"names":[],"mappings":"AAEA,+BAEE,4CAAA,CADA,kCAAA,CAEA,sCCEF,CDCA,oCACE,UCEF,CDCA,2BAME,WAAA,CAJA,2FAAA,CAGA,eAAA,CAJA,+CCKF,CDGA,0BACE,2CAAA,CACA,iBCAF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HelpPopover": "jut__HelpPopover__HelpPopover",
	"popoverContainer": "jut__HelpPopover__popoverContainer",
	"content": "jut__HelpPopover__content",
	"footer": "jut__HelpPopover__footer"
};
export default ___CSS_LOADER_EXPORT___;
