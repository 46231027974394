// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__RightSideBar__rightSideBar{background-color:var(--GW-RIGHT-SIDEBAR-SIDE-BACKGROUND-COLOR)}.jut__RightSideBar__bar{background-color:var(--GW-RIGHT-SIDEBAR-BAR-BACKGROUND-COLOR);box-shadow:var(--GW-SHADOW-SIDE);z-index:0}.jut__RightSideBar__icons{flex:1 1}.jut__RightSideBar__footer{border-top:1px solid var(--GW-BORDER-COLOR);padding:var(--GW-SPACING-3);text-align:center}.jut__RightSideBar__hiddenSide{display:none}.jut__RightSideBar__contentWrapper{background-color:var(--GW-RIGHT-SIDEBAR-SIDE-BACKGROUND-COLOR);width:var(--GW-RIGHT-SIDEBAR-SIDE-WIDTH)}.jut__RightSideBar__collapseOuterContentWrapper{box-shadow:var(--GW-SHADOW-SIDE)}", "",{"version":3,"sources":["webpack://./../../../src/RightSideBar/RightSideBar.module.scss","webpack://./../../platform/@jutro/router/RightSideBar/RightSideBar.module.css"],"names":[],"mappings":"AAAA,iCACE,8DCCF,CDEA,wBACE,6DAAA,CACA,gCAAA,CACA,SCCF,CDEA,0BACE,QCCF,CDEA,2BAGE,2CAAA,CADA,2BAAA,CADA,iBCGF,CDEA,+BACE,YCCF,CDEA,mCAGE,8DAAA,CADA,wCCCF,CDGA,gDACE,gCCAF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rightSideBar": "jut__RightSideBar__rightSideBar",
	"bar": "jut__RightSideBar__bar",
	"icons": "jut__RightSideBar__icons",
	"footer": "jut__RightSideBar__footer",
	"hiddenSide": "jut__RightSideBar__hiddenSide",
	"contentWrapper": "jut__RightSideBar__contentWrapper",
	"collapseOuterContentWrapper": "jut__RightSideBar__collapseOuterContentWrapper"
};
export default ___CSS_LOADER_EXPORT___;
