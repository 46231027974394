// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__QuickViewHeaderActions__trigger{align-items:center;display:flex;justify-content:space-between;padding:0 var(--GW-LAYOUT-3)}", "",{"version":3,"sources":["webpack://./../../../../src/quick-view/QuickViewHeaderActions/QuickViewHeaderActions.module.scss","webpack://./../../platform/@jutro/legacy/quick-view/QuickViewHeaderActions/QuickViewHeaderActions.module.css"],"names":[],"mappings":"AAAA,sCACE,kBAAA,CACA,YAAA,CACA,6BAAA,CACA,4BCCF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"trigger": "jut__QuickViewHeaderActions__trigger"
};
export default ___CSS_LOADER_EXPORT___;
