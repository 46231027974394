// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__ColumnEntry__columnEntry{align-items:center;background-color:var(--GW-COLUMN-ENTRY-BACKGROUND-COLOR);border:1px solid transparent;border-radius:var(--GW-BORDER-RADIUS);display:flex;height:var(--GW-COLUMN-ENTRY-HEIGHT);justify-content:space-between;margin:var(--GW-SPACING-1);padding:0 var(--GW-SPACING-4);transition:background-color var(--GW-ANIMATION-DURATION);width:var(--GW-COLUMN-ENTRY-WIDTH)}.jut__ColumnEntry__columnEntry:hover{background-color:var(--GW-COLUMN-ENTRY-BACKGROUND-COLOR-HOVER)}", "",{"version":3,"sources":["webpack://./../../../../src/datatable/columnsConfig/ColumnEntry.module.scss","webpack://./../../platform/@jutro/legacy/datatable/columnsConfig/ColumnEntry.module.css"],"names":[],"mappings":"AAEA,+BAEE,kBAAA,CAIA,wDAAA,CACA,4BAAA,CACA,qCAAA,CAPA,YAAA,CAIA,oCAAA,CAFA,6BAAA,CAOA,0BAAA,CADA,6BAAA,CAEA,wDAAA,CAPA,kCCSF,CDAE,qCACE,8DCEJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"columnEntry": "jut__ColumnEntry__columnEntry"
};
export default ___CSS_LOADER_EXPORT___;
