// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__DisplayValueGeneric__displayValue{display:block;font-family:var(--JDS-FONT-LABEL-MEDIUM-STRONG-FONT-FAMILY-INTERNAL);font-size:var(--JDS-FONT-LABEL-MEDIUM-STRONG-FONT-SIZE-INTERNAL);font-weight:var(--JDS-FONT-LABEL-MEDIUM-STRONG-FONT-WEIGHT-INTERNAL);line-height:var(--JDS-FONT-LABEL-MEDIUM-STRONG-LINE-HEIGHT-INTERNAL);width:100%}", "",{"version":3,"sources":["webpack://./../../../../../src/fields/internal/DisplayValueGeneric/DisplayValueGeneric.module.scss","webpack://./../../platform/@jutro/components/fields/internal/DisplayValueGeneric/DisplayValueGeneric.module.css"],"names":[],"mappings":"AAEA,wCACE,aAAA,CAEA,oEAAA,CACA,gEAAA,CACA,oEAAA,CACA,oEAAA,CAJA,UCMF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"displayValue": "jut__DisplayValueGeneric__displayValue"
};
export default ___CSS_LOADER_EXPORT___;
