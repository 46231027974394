// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__SkipNav__skipNav{height:1px;outline:none;overflow:hidden;position:absolute;width:1px}", "",{"version":3,"sources":["webpack://./../../src/SkipNav.module.scss","webpack://./../../platform/@jutro/app/SkipNav.module.css"],"names":[],"mappings":"AAAA,uBAGE,UAAA,CAEA,YAAA,CADA,eAAA,CAHA,iBAAA,CACA,SCIF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skipNav": "jut__SkipNav__skipNav"
};
export default ___CSS_LOADER_EXPORT___;
