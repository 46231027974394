// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__Breadcrumb__breadcrumb{display:inline-block}.jut__Breadcrumb__breadcrumb:empty{display:none}.jut__Breadcrumb__breadcrumb ul{margin:0;padding:0}.jut__Breadcrumb__breadcrumb li{display:inline}.jut__Breadcrumb__breadcrumb .jut__Breadcrumb__breadcrumbLink,.jut__Breadcrumb__breadcrumb .jut__Breadcrumb__currentLink{font-size:var(--GW-FONT-SIZE-BODY);padding:var(--GW-SPACING-1) var(--GW-SPACING-2);vertical-align:middle}.jut__Breadcrumb__breadcrumb .jut__Breadcrumb__divider{color:var(--GW-BREADCRUMB-DIVIDER-COLOR);font-size:var(--GW-FONT-SIZE-H4);margin:var(--GW-SPACING-2);vertical-align:middle}", "",{"version":3,"sources":["webpack://./../../../src/Breadcrumb/Breadcrumb.module.scss","webpack://./../../platform/@jutro/router/Breadcrumb/Breadcrumb.module.css"],"names":[],"mappings":"AAAA,6BACE,oBCCF,CDCE,mCACE,YCCJ,CDEE,gCAEE,QAAA,CADA,SCCJ,CDGE,gCACE,cCDJ,CDIE,yHAIE,kCAAA,CADA,+CAAA,CADA,qBCAJ,CDKE,uDAIE,wCAAA,CAHA,gCAAA,CAEA,0BAAA,CADA,qBCDJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumb": "jut__Breadcrumb__breadcrumb",
	"breadcrumbLink": "jut__Breadcrumb__breadcrumbLink",
	"currentLink": "jut__Breadcrumb__currentLink",
	"divider": "jut__Breadcrumb__divider"
};
export default ___CSS_LOADER_EXPORT___;
