// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__LayoutContainer__wrapper{margin:0 auto}.jut__LayoutContainer__wrapper .jut__LayoutContainer__container{padding-bottom:var(--GW-LAYOUT-6)}", "",{"version":3,"sources":["webpack://./../../../src/layouts/LayoutContainer.module.scss","webpack://./../../platform/@jutro/components/layouts/LayoutContainer.module.css"],"names":[],"mappings":"AAAA,+BACE,aCCF,CDCE,gEACE,iCCCJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "jut__LayoutContainer__wrapper",
	"container": "jut__LayoutContainer__container"
};
export default ___CSS_LOADER_EXPORT___;
