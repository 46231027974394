// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__Loader__loader{align-items:center;display:flex;flex-direction:column;height:100%;justify-content:center;z-index:var(--GW-LOADER-Z-INDEX)}.jut__Loader__text{padding:var(--GW-SPACING-2) var(--GW-SPACING-3);text-align:center}.jut__Loader__loaderAlertWrapper{align-items:center;display:flex;flex-direction:column}", "",{"version":3,"sources":["webpack://./../../../../../src/widgets/loading/loader/Loader.module.scss","webpack://./../../platform/@jutro/components/widgets/loading/loader/Loader.module.css"],"names":[],"mappings":"AAAA,qBAGE,kBAAA,CAFA,YAAA,CACA,qBAAA,CAIA,WAAA,CAFA,sBAAA,CACA,gCCEF,CDEA,mBAEE,+CAAA,CADA,iBCEF,CDEA,iCAGE,kBAAA,CAFA,YAAA,CACA,qBCEF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "jut__Loader__loader",
	"text": "jut__Loader__text",
	"loaderAlertWrapper": "jut__Loader__loaderAlertWrapper"
};
export default ___CSS_LOADER_EXPORT___;
