// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__ConfirmationModal__overlayConfirmation{z-index:calc(1 + var(--GW-Z-INDEX-4))}", "",{"version":3,"sources":["webpack://./../../../../../src/widgets/modal-next/ConfirmationModal/ConfirmationModal.module.scss","webpack://./../../platform/@jutro/components/widgets/modal-next/ConfirmationModal/ConfirmationModal.module.css"],"names":[],"mappings":"AAEA,6CACE,qCCEF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlayConfirmation": "jut__ConfirmationModal__overlayConfirmation"
};
export default ___CSS_LOADER_EXPORT___;
