// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__FieldMessage__messagesOuterContainer{display:flex;flex-direction:column;font-family:var(--JDS-FONT-LABEL-MEDIUM-FONT-FAMILY-INTERNAL);font-size:var(--JDS-FONT-LABEL-MEDIUM-FONT-SIZE-INTERNAL);font-weight:var(--JDS-FONT-LABEL-MEDIUM-FONT-WEIGHT-INTERNAL);letter-spacing:var(--JDS-FONT-LABEL-MEDIUM-LETTER-SPACING-INTERNAL);line-height:var(--JDS-FONT-LABEL-MEDIUM-LINE-HEIGHT-INTERNAL);margin-top:var(--JDS-VALIDATION-GAP-BOTTOM-INTERNAL);-webkit-text-decoration:var(--JDS-FONT-LABEL-MEDIUM-TEXT-DECORATION-INTERNAL);text-decoration:var(--JDS-FONT-LABEL-MEDIUM-TEXT-DECORATION-INTERNAL);text-transform:var(--JDS-FONT-LABEL-MEDIUM-TEXT-CASE-INTERNAL)}.jut__FieldMessage__fieldMessageIcon{font-size:var(--JDS-VALIDATION-ICON-SIZE-INTERNAL);margin-right:var(--JDS-VALIDATION-GAP-INNER-INTERNAL)}.jut__FieldMessage__error{color:var(--JDS-COLOR-ICON-ERROR-INTERNAL)}.jut__FieldMessage__messageInnerContainer{align-items:center;display:flex}", "",{"version":3,"sources":["webpack://./../../../../../src/fields/internal/FieldMessage/FieldMessage.module.scss","webpack://./../../platform/@jutro/components/fields/internal/FieldMessage/FieldMessage.module.css"],"names":[],"mappings":"AAAA,2CACE,YAAA,CACA,qBAAA,CACA,6DAAA,CACA,yDAAA,CACA,6DAAA,CAIA,mEAAA,CAHA,6DAAA,CAIA,oDAAA,CAHA,6EAAA,CAAA,qEAAA,CACA,8DCGF,CDEA,qCACE,kDAAA,CACA,qDCCF,CDEA,0BACE,0CCCF,CDEA,0CAEE,kBAAA,CADA,YCEF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messagesOuterContainer": "jut__FieldMessage__messagesOuterContainer",
	"fieldMessageIcon": "jut__FieldMessage__fieldMessageIcon",
	"error": "jut__FieldMessage__error",
	"messageInnerContainer": "jut__FieldMessage__messageInnerContainer"
};
export default ___CSS_LOADER_EXPORT___;
