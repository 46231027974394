// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__Popper__popper{position:absolute;z-index:var(--GW-Z-INDEX-4)}.jut__Popper__hidden{visibility:hidden}", "",{"version":3,"sources":["webpack://./../../../../src/widgets/Popper/Popper.module.scss","webpack://./../../platform/@jutro/components/widgets/Popper/Popper.module.css"],"names":[],"mappings":"AAAA,qBAEE,iBAAA,CADA,2BCEF,CDEA,qBACE,iBCCF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popper": "jut__Popper__popper",
	"hidden": "jut__Popper__hidden"
};
export default ___CSS_LOADER_EXPORT___;
