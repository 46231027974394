// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__HeaderActions__headerActionsWrapper{flex-wrap:nowrap;row-gap:var(--GW-SPACING-4)}@media (max-width:580px){.jut__HeaderActions__headerActionsWrapper{flex-wrap:wrap}}", "",{"version":3,"sources":["webpack://./../../../../../src/dataview/features/headerActions/HeaderActions.module.scss","webpack://./../../platform/@jutro/legacy/dataview/features/headerActions/HeaderActions.module.css","webpack://./../../../../../../node_modules/@jutro/theme/assets/sass/mixins/breakpoints-mixins.scss"],"names":[],"mappings":"AAEA,0CAEE,gBAAA,CADA,2BCGF,CCwCI,yBF5CJ,0CAKI,cCGF,CACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerActionsWrapper": "jut__HeaderActions__headerActionsWrapper"
};
export default ___CSS_LOADER_EXPORT___;
