// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__TagCollection__collection{align-items:center;display:flex;flex:1 1;flex-wrap:nowrap;margin:0;overflow:hidden}.jut__TagCollection__collection.jut__TagCollection__expanded{flex-wrap:wrap}.jut__TagCollection__collectionTag{margin-right:var(--GW-SPACING-3)}", "",{"version":3,"sources":["webpack://./../../../../src/widgets/TagCollection/TagCollection.module.scss","webpack://./../../platform/@jutro/components/widgets/TagCollection/TagCollection.module.css"],"names":[],"mappings":"AAAA,gCAEE,kBAAA,CAEA,YAAA,CAEA,QAAA,CADA,gBAAA,CAJA,QAAA,CAEA,eCIF,CDCE,6DACE,cCCJ,CDGA,mCACE,gCCAF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collection": "jut__TagCollection__collection",
	"expanded": "jut__TagCollection__expanded",
	"collectionTag": "jut__TagCollection__collectionTag"
};
export default ___CSS_LOADER_EXPORT___;
